.product-card {
  border: 1px solid #ddd;
  background-color: #4c495d;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-card h3 {
  margin-top: 15px;
  font-size: 18px;
}

.product-card a {
  text-decoration: none;
  color: inherit;
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) {
  .product-card {
    margin: 35px; /* Increased margin for mobile screens */
  }
}
