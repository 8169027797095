/* WhatsAppButton.css */
.whatsapp-button {
  position: fixed;
  bottom: 120px;
  right: 20px;
  background-color: #25d366;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.whatsapp-button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}

/* Media Query for Desktop Screens */
@media (min-width: 768px) {
  .whatsapp-button {
    padding: 16px; /* Increase padding for larger size */
  }

  .whatsapp-button img {
    width: 40px; /* Increase the image size */
    height: 40px;
  }
}
