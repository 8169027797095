/* AdminSales.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
}

.admin-sales {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.admin-sales h1 {
  text-align: center;
  color: #333333;
  margin-bottom: 20px;
  font-size: 24px;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;
}

.admin-sales .top-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-sales .top-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.admin-sales .logout-button {
  background-color: #dc3545;
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
}

.admin-sales .see-all-data-button {
  background-color: #17a2b8;
  color: white;
}

.admin-sales .logout-button:hover {
  background-color: #c82333;
}

.admin-sales .see-all-data-button:hover {
  background-color: #138496;
}

.success-message, .error-message {
  margin: 20px 0;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.add-sales-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-sales-form label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #555555;
}

.add-sales-form input {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.add-sales-form input[type="text"],
.add-sales-form input[type="date"] {
  width: 100%;
}

.add-sales-form button[type="submit"] {
  align-self: center;
  width: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.add-sales-form button[type="submit"]:hover {
  background-color: #0056b3;
}

.add-sales-form div {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sales-data {
  margin-top: 20px;
}

.sales-data h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
  font-size: 22px;
  border-bottom: 2px solid #17a2b8;
  padding-bottom: 10px;
}

.sales-data table {
  width: 100%;
  border-collapse: collapse;
}

.sales-data table th,
.sales-data table td {
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: left;
}

.sales-data table th {
  background-color: #f2f2f2;
}

.sales-data table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sales-data table tr:hover {
  background-color: #f1f1f1;
}

.sales-data button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 2px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 14px;
}

.sales-data button:hover {
  background-color: #218838;
}
