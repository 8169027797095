/* Container styles */
.about-container {
  background-color: #4d194d;
  padding: 3rem 2rem;
  margin: 0 auto; /* Center the container horizontally */
  max-width: 100%;
  
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  align-items: center;
  text-align: center; /* Center the content */
}

.about-container h1{
  color: #6e6e71;
  font-size: 2.5em;
  margin-bottom: 20px;

}

/* Heading styles */
.about-heading {
  font-family: sans-serif;
  font-size: 5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 2rem;
  text-align: center; /* Center the heading */
}

/* Intro paragraph styles */
.about-intro {
  font-family: sans-serif;
  padding: 1.5rem;
  
  border-radius: 0.75rem;
  margin-bottom: 2rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

/* Highlight text styles */
.highlight-text {
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
}

/* List item styles */
.about-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}

.about-list-item {
  width: calc(50% - 1.5rem);
  padding: 1.5rem;
  background-color: #f5f5f5;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

/* Responsiveness adjustments */
@media (max-width: 576px) {
  .about-container {
    max-width: 95%;
    padding: 2rem 1rem;
  }

  .about-heading {
    font-size: 1.8rem;
    
  }

  .about-list-item {
    width: 100%;
  }

  .about-text {
    font-size: 14px;
    line-height: 1.4;
    text-align: center; /* Center-align text on small screens */
  }

  .about-heading {
    font-size: 1.6rem;
  }
}

/* General text styles */
.about-text {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  text-align: justify;
  margin-bottom: 0.5rem;
}




/* Signature text styles */
.signature-text {
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
}

.last {
  color: #f5f5f5;}

@media (min-width: 768px) {
  .about-container {
    margin: 0 auto; /* Center the container horizontally */
  }
}
