/* Product Details Styles */
.product-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
}

.product-details h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-details img {
  width: 100%;
  max-width: 500px; /* Adjust maximum width for main product image */
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.product-details p {
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

.product-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 20px;
}

.product-gallery img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
