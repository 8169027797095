/* src/components/Services.css */
.service-page {
    text-align: center;
    padding: 20px;
    background-color: #050a44;
  }

  .service-page h1{
      color: #cb2d6f;
      font-size: 2.5em;
      margin-bottom: 20px;
    
  }

  .service-page p{
    font-size: 1.2em;
  color: #b3b4bd;
  }
  
  .service-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  
  