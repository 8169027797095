/* src/components/ServiceCard.css */
.service-card {
  background-color: #022140;
    border: 1px solid #ddd;
    padding: 15px;
    margin: 15px;
    border-radius: 8px;
    text-align: center;
    max-width: 300px;
  }
  
  .card-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .card-title {
    margin-top: 10px;
    color: #f070a1;
  }
  
  .card-content {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .read-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  