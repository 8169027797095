.footer {
  text-align: center;
  background-color: #333;
  color: #fff;
}

.footer-section {
  background-color: #222;
  color: #fff;
  padding: 30px 0;
}

.footer-copyright {
  background-color: #111;
  color: #fff;
  padding: 20px 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
}

h3 {
  color: #ccc;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
}

p {
  font-size: 16px;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  font-size: 24px;
  margin-right: 15px;
}

/* Media query for mobile screens */
@media only screen and (max-width: 767px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  h3 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  .social-icons a {
    font-size: 20px;
  }
}
