/* ContactForm.css */
.contact-form {
  display: grid;
  grid-gap: 15px;
  width: 90%; /* Increase the width by 20% */
  max-width: 500px;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center the text within the form */
}

.contact-form h2 {
  font-size: 1.5em; /* Adjust the font size as needed */
  margin-bottom: 20px; /* Add some space between the heading and the form fields */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 4px;
}

button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

/* Additional styling for ContactForm can be added here */
