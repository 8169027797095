/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #2c3e50;
  color: #ecf0f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
}

.navbar-brand {
  width: 50px;
  height: 50px;
  background-color: #3498db;
  border: 1px solid #2980b9;
  border-radius: 50%;
  overflow: hidden;
}

.navbar-brand img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
}

.navbar-menu a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 1.2rem;
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-menu a:hover {
  color: #ffd700;
  background-color: rgba(255, 255, 255, 0.1);
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 1.7rem;
  color: #ecf0f1;
  transition: color 0.3s ease;
}

.mobile-menu-icon:hover {
  color: #ffd700;
}

/* Dropdown styles */
.navbar-menu .dropdown {
  position: relative;
}

.navbar-menu .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.navbar-menu .dropdown:hover .dropdown-content {
  display: block;
  flex-direction: column;
}

.navbar-menu .dropdown-content a {
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  color: #333;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.navbar-menu .dropdown-content a:hover {
  background-color: #f2f2f2;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 67px; /* Adjusted top position for better spacing */
    left: 0;
    width: 100%;
    background-color: #2c3e50;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-top: 16px; /* Added padding-top for spacing */
  }

  .navbar-menu.mobile-menu-open {
    opacity: 1;
    visibility: visible;
  }

  .mobile-menu-icon {
    display: block;
  }

  .navbar-menu a {
    margin-bottom: 6px; /* Adjusted margin between menu items */
    width: 100%; /* Full width for menu items */
    text-align: center; /* Center-align menu item text */
  }

  .navbar-brand {
    margin-bottom: 10px; /* Increased margin for the brand icon */
  }

  .navbar-menu a:last-child {
    margin-bottom: 12px; /* Increased margin for the last item (Contact) */
  }

  .share-button-container {
    margin-top: 10px; /* Adjusted margin-top for the share button container */
    text-align: center; /* Center-align share button */
  }
}
