.home-container {
  text-align: center;
  background-color: #141619; /* Dark background color */
  color: #B3B4Bd; /* Light color for text */
  padding-top: 20px;
  padding-bottom: 20px;
}

.hero-section {
  padding: 0px;
  border-radius: 10px;
  background-color: #141619; /* Slightly lighter background for hero section */
  color: #fff; /* Light color for text */
}

.hero-section h1 {
  font-size: 3.0em;
  margin-bottom: 5px; /* Adjust the bottom margin to control the gap */
  margin-top: -20px;
}

.hero-section p {
  font-size: 1.2em;
  margin-top: 5px; /* Adjust the top margin to control the gap */
}

.image-section {
  margin-top: 20px;
}

.hero-image {
  width: 100%;
}

.slogan-section {
  margin-top: 20px;
}

.slogan-section h2 {
  color: #fff; /* Light color for headings */
}

.slogan-section p {
  color: #ddd; /* Light gray for paragraph text */
  font-size: 1.4em;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .hero-section {
    height: auto; /* Allow the height to adjust based on content */
  }

  .hero-section h1 {
    font-size: 2.0em;
    margin-bottom: 5px; /* Adjust the bottom margin for smaller screens */
    margin-top: -20px;
  }

  .hero-section p {
    font-size: 0.9em;
    margin-top: 5px; /* Adjust the top margin for smaller screens */
  }

  .slogan-section h2 {
    font-size: 1.1em;
  }

  .slogan-section p {
    font-size: 0.8em;
  }
}

/* ... existing styles */

.video-section {
  /* Additional styles for video section */
}

.video-section video {
  width: 100%; /* Ensures video fills container width */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .video-section video {
    height: 150px; /* Adjust height for smaller screens */
  }
}
